*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Josefin Slab',
  sans-serif;
}

a#link {
  background: white;
  color: #2F2F41;
  text-decoration: none;
  padding: 10px;
  font-weight: bold;
  border: solid #9FC756 2px;
  border-radius: 20px;

 
}


a#link:hover{
  color:#9FC756;
  background: #2F2F41;
}