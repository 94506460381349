
.termbody{
	background: white;
	
}
body{
	width: 100vw;
	height: 100vh;
	padding: 0;
	background: #2F2F41;
	background:cover;
}

.termtitle {
	text-align: center;
	margin-top: 100px;
	color: white;
}

.termtitle h1{
	text-align: center;
	font-size: 44px;
}

.termtitle h2{
	padding-top: 20px;
	font-size: 20px;
}
.term form{
	background: white;
	margin-top: 30px;
	margin-bottom: 20px;
}
.term-form{
	
	width: 650px;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 20px;


}

@media screen and (max-width:700px){
	.term-form{
	width: 350px;
	}
}


@media screen and (max-width:700px){
	.term-control{
	width: 300px;
	}
}

.term h3{
	padding: 20px;
	text-align: left;
	font-size: 15px;
}
.term p{
margin:20px;
padding-bottom: 10px;
text-align: left;	
}
