
body{
	width: 100vw;
	height: 100vh;
	padding: 0;
	text-align: center;
	background: #2F2F41;
}

.title{
	margin-top: 100px;
	color: white;
}

.title h1{
	font-size: 44px;
}

.title h2{
	padding-top: 20px;
	font-size: 20px;
}
form{
	background: white;
	margin-top: 30px;
}
.contact-form{
	
	width: 650px;
	height: auto;
	margin-left: auto;
	margin-right: auto;


}

@media screen and (max-width:700px){
	.contact-form{
	width: 350px;
	}
}

.form-control{
	padding-top: 20px;
	padding-bottom: 20px;
	width: 600px;
	background: transparent;
	border: none;
	outline: none;
	border-bottom: 1px solid #9FC756;
	color: #2F2F41;
	font-size: 18px;
	margin-top: 10px;

}

@media screen and (max-width:700px){
	.form-control{
	width: 300px;
	}
}

input{
	height: 45px;
}

form .submit{
	background: #9FC756;
	color: white;
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 2px;
	height: 50px;
	margin-top: 15px;
	margin-bottom: 20px;
}

form .submit:hover{
	background: #2F2F41;
	transition: all 0.2s ease-in-out;
	color: #9FC756;
	border: solid 1px #9FC756; 
}